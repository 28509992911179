var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"elevation":"0","justify":"between","align":"center","color":"grey lighten-3 py-4"}},[_c('v-form',{ref:"form",attrs:{"justify":"between","align":"center","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12","md":"8","lg":"8"}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"3"}},[_c('CardAutocomplete',{attrs:{"items":_vm.segment_list,"item_text":"value","item_value":"id","hint":_vm.$t('segmentUpload.fields.segments'),"placeholder":_vm.$t(
									'segmentUpload.fields.plaeceholder_fields'
								),"label":_vm.$t('segmentUpload.fields.segments'),"multiple":true,"colapse_selection":true,"dense":false,"required":true},on:{"click":_vm.fetchSegmentList},model:{value:(_vm.segment_data),callback:function ($$v) {_vm.segment_data=$$v},expression:"segment_data"}})],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"3"}},[_c('CardAutocomplete',{attrs:{"items":_vm.resources.continents,"item_text":"value","item_value":"id","hint":_vm.$t('segmentUpload.fields.continent'),"placeholder":_vm.$t(
									'segmentUpload.fields.plaeceholder_fields'
								),"label":_vm.$t('segmentUpload.fields.continent'),"dense":false,"required":false},model:{value:(_vm.continent_data),callback:function ($$v) {_vm.continent_data=$$v},expression:"continent_data"}})],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"3"}},[_c('v-card',{staticClass:"mx-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"transparent"}},[_c('div',[_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
											_vm.$t(
												'segmentUpload.fields.update_file'
											)
										)}})],1),_c('v-file-input',{staticClass:"label-fixed static",attrs:{"accept":".txt","max-size":67108864,"rules":_vm.file_rule,"label":_vm.$t(
										'segmentUpload.fields.btn_upload_file'
									),"placeholder":_vm.$t(
										'segmentUpload.fields.btn_upload_file'
									),"prepend-icon":"","hide-details":"false","small-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function({ index, text }){return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}]),model:{value:(_vm.file_select),callback:function ($$v) {_vm.file_select=$$v},expression:"file_select"}})],1)],1),_c('v-col',{staticClass:"d-flex justify-start align-center ma-0 pa-0",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"mx-2 px-8",attrs:{"outlined":"","rounded":"","color":"secondary"},on:{"click":function($event){return _vm.handleSubmit()}}},[_vm._v(" "+_vm._s(_vm.$t("customListItem.actions.upload"))+" ")])],1)],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c('p',{staticClass:"subtitle-2 ma-5 color-secondary"},[_vm._v(" "+_vm._s(_vm.getTotalSegmentUpload)+" ")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('GridTable',{attrs:{"context":_vm.context,"headers":_vm.getColumnsDef,"items":_vm.prepareTableContent,"current_page":Number(_vm.getResultPage.current_page),"next_page_url":String(_vm.getResultPage.next_page_url),"path":String(_vm.getResultPage.path),"per_page":Number(_vm.getResultPage.per_page),"prev_page_url":String(_vm.getResultPage.prev_page_url),"to":Number(_vm.getResultPage.to),"total":Number(_vm.getResultPage.total),"selectionRows":String(_vm.selectionRows)},on:{"update-paginate":_vm.updatePaginate}})],1)],1)],1)],1),_c('v-snackbar',{attrs:{"color":"secondary","left":"","shaped":"","bottom":"","timeout":"2000"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.$t("segmentUpload.messager.snackbar"))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }